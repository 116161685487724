/* eslint-disable no-promise-executor-return */

import { useContext, useRef, useState } from 'react';
import find from 'lodash/find';
import StepWizard, { StepWizardProps } from 'react-step-wizard';
import {
  gql, useApolloClient, useLazyQuery, useMutation,
} from '@apollo/client';
import { useTranslation } from 'react-i18next';
import WithStepWizard, { StepProps } from '../common/wrappers/withStepWizard';
import AccountType from './accountType';
import SelectTheme from './selectTheme';
import ThemeDescription from './themeDescription';
import PortfolioDisclaimer from './portfolioDisclaimer';
import IncompleteAgreements from '../documents/incompleteAgreements';
import {
  CLOSE_ACCOUNT,
  CLOSE_SUB_ACCOUNT,
  CREATE_ACCOUNT,
  CREATE_AFFILIATE,
  CREATE_SUB_ACCOUNT,
  UPDATE_ACCOUNT,
  UPDATE_AFFILIATE, UPDATE_AFFILIATIONS,
  UPDATE_SUB_ACCOUNT,
} from './graphql';
import {
  AccountTypes,
  accountTypesRESP,
  AffiliationRelations,
  AffiliationTypes,
  ApplyForGovFunds,
  getAccountTypeName,
  getSubAccountUniqueName,
  ThemeAttributes,
} from './resources';
import TrackingBar from '../common/headers/trackingBar';
import { UserContext } from '../../providers/userContextProvider';
import AccountTypeAuxiliaryWizard, { AffiliateUser, Affiliation } from './createAccountAuxiliaryWizard';
import { availableFeatureFlagsVar, userIdVar } from '../../utils/localVariables';
import { formatDate, isTestEnv } from '../../utils/commonMethods';
import RespAccountType from './respAccountType';
import EnableTLH from './taxLossHarvesting/enableTLH';
import ExistingRespAccountType from './existingRespAccountType';
import { generateUniqueRequestId } from '../../utils/generic';
import { ovAnalyticsEvents } from '../../utils/firebase';
import { FinancialProduct } from '../../interfaces/financialProduct';
import { lockedInAccountsWithAuxiliaryStep } from '../../utils/accountUtil';

const AccountTypeStep = WithStepWizard(AccountType);
const EnableTLHStep = WithStepWizard(EnableTLH);
const SelectThemeStep = WithStepWizard(SelectTheme);
const ThemeDescriptionStep = WithStepWizard(ThemeDescription);
const PortfolioDisclaimerStep = WithStepWizard(PortfolioDisclaimer);
const IncompleteAgreementsStep = WithStepWizard(IncompleteAgreements);
const AccountTypeAuxiliaryStepWizard = WithStepWizard(AccountTypeAuxiliaryWizard);
const ExistingRespAccountTypeStep = WithStepWizard(ExistingRespAccountType);
const RespAccountTypeStep = WithStepWizard(RespAccountType);

export const FETCH_ACCOUNT_SUBACCOUNT = gql`
  query fetchAccount($accountId: ObjectID!) {
    fetchAccount(accountId: $accountId) {
      account {
        subAccounts {
          id
          state
        }
      }
    }
  }
`;

export interface SubAccountRef {
  id?: string,
  goalId: string,
  themeId?: string,
  accountId?: string,
  accountType?: AccountTypes,
  name?: string,
  financialProductId?: string,
  financialProductDisclaimer?: string,
}

export interface AccountRef {
  id?: string,
  type?: AccountTypes,
  applyForGovFunds?: Array<ApplyForGovFunds>,
}

export interface OriginalAccountRef {
  id?: string,
  type?: AccountTypes,
  state?: string,
  themeId?: string,
}

interface Props {
  subAccount?: { id: string, account?: { id?: string, type: AccountTypes, state?: string }, },
  goalId: string,
  financialProduct?: FinancialProduct,
  onContinue?: () => void,
  goBack?: () => void,
  stepProps?: StepProps,
  stepTitle?: string,
  onStepChanged?: (step: number) => void,
  showExistingRespFlow?: boolean,
  onSubAccountCreated?: (subAccount: Partial<SubAccountRef>) => void,
  shouldHideAgreement?: boolean,
  accountsToExclude?: AccountTypes[],
}

const CreateSubAccountWizard = ({
  subAccount,
  goalId,
  financialProduct,
  onContinue,
  goBack,
  stepTitle,
  stepProps,
  onStepChanged,
  showExistingRespFlow,
  onSubAccountCreated,
  shouldHideAgreement,
  accountsToExclude,
}: Props): JSX.Element => {
  const [state, updateState] = useState({
    SW: {} as StepWizardProps,
  });
  const absoluteTotalSteps = 26;
  const hasGoalPortfolio = !!(financialProduct?.id && financialProduct?.theme?.id);
  const [loading, setLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<ThemeAttributes>();
  const [hasMultipleThemes, setHasMultipleThemes] = useState<boolean>(false);
  const [hasPortfolioDisclaimer, setHasPortfolioDisclaimer] = useState<boolean>(false);
  const subAccountRef = useRef<SubAccountRef>({ id: subAccount?.id, goalId });
  const createdAccountRef = useRef<AccountRef>({});
  const enableTLHRef = useRef<boolean>(false);
  const originalAccount = useRef<OriginalAccountRef | undefined>({
    id: subAccount?.account?.id,
    themeId: '',
    type: subAccount?.account?.type,
    state: subAccount?.account?.state,
  });
  const callbackRef = useRef<() => void>();
  const graphqlClient = useApolloClient();
  const [subAccountId, setSubAccountId] = useState(subAccount?.id ?? '');
  const { userContext, setUserContext } = useContext(UserContext);
  const [, setSubAccountType] = useState<AccountTypes | undefined>(subAccount?.account?.type);
  const { t } = useTranslation(['user']);
  const [isExistingRespAccount, setIsExistingRespAccount] = useState<boolean>(false);
  const [affiliationList, setAffiliationList] = useState<Array<Affiliation>>([]);
  const [applyForGovFunds, setApplyForGovFunds] = useState<Array<ApplyForGovFunds>>([]);
  const [affiliateUserList, setAffiliateUserList] = useState<Array<AffiliateUser>>([]);
  const [updateAffiliationsMutation] = useMutation(UPDATE_AFFILIATIONS);
  const [createAccountMutation] = useMutation(CREATE_ACCOUNT);
  const [updateAccountMutation] = useMutation(UPDATE_ACCOUNT);
  const [createAffiliateMutation] = useMutation(CREATE_AFFILIATE);
  const [updateAffiliateMutation] = useMutation(UPDATE_AFFILIATE);
  const userIsMarriedOrCommonLaw: boolean = userContext.maritalStatus === 'MARRIED' || userContext.maritalStatus === 'COMMON_LAW';
  const [fetchSubAccount] = useLazyQuery(FETCH_ACCOUNT_SUBACCOUNT, {
    variables: {
      accountId: originalAccount.current?.id ?? '',
    },
    fetchPolicy: 'network-only',
  });
  const setSubAccountRef = (att: Partial<SubAccountRef>): void => {
    subAccountRef.current = { ...subAccountRef.current, ...att };
    setSubAccountType(subAccountRef.current.accountType);
  };

  const setEnableTLHStep = (att: Partial<boolean>): void => {
    enableTLHRef.current = att;
  };

  const setCreatedAccountRef = (att: Partial<AccountRef>): void => {
    createdAccountRef.current = { ...createdAccountRef.current, ...att };
  };

  const setInstance = (SW: StepWizardProps): void => updateState({ ...state, SW });
  const currentSubAccount = subAccount && subAccount.id
    ? find(userContext.subAccounts, { id: subAccount?.id })
    : { account: { type: subAccountRef.current.accountType }, theme: { id: subAccountRef.current.themeId } };
  const respAccounts: (AccountTypes | undefined)[] = [AccountTypes.RESP, ...accountTypesRESP.map(((item) => item.key))];
  const isTFSAOrRRSP = subAccountRef.current.accountType && [AccountTypes.TFSA, AccountTypes.RRSP].includes(subAccountRef.current.accountType);
  const shouldCreateNewTFSAOrRRSP = (isTFSAOrRRSP && affiliateUserList.length > 0) ?? false;
  const hasAuxStep: boolean = respAccounts.includes(subAccountRef.current.accountType)
    || (subAccountRef.current.accountType && lockedInAccountsWithAuxiliaryStep.includes(subAccountRef.current.accountType))
    || shouldCreateNewTFSAOrRRSP;
  const hasTlhEnabled = (accountType: AccountTypes): boolean => accountType === AccountTypes.PERSONAL
    && (userContext.subAccounts?.filter((item) => item?.account?.type === AccountTypes.PERSONAL).length === 0)
    && isTestEnv && availableFeatureFlagsVar().includes('TAX_LOSS_HARVESTING');

  const onUpdateSubAccountCompleted = (data: {
    updateSubAccount: { subAccount: { id: string, name: string, allowClientDeposits?: boolean, state: string } }
  }): void => {
    console.log({ event: 'UPDATED_SUB_ACCOUNT', subAccountId: data.updateSubAccount.subAccount.id });
    const otherSubAccounts = (subAccount ? userContext.subAccounts?.filter((s) => s.id !== subAccount.id) : userContext.subAccounts) ?? [];
    const account = { id: subAccountRef.current.accountId ?? '', type: subAccountRef.current.accountType as AccountTypes };
    const isNewAccount = !userContext.accounts?.find((a) => a.id === subAccountRef.current.accountId);
    setUserContext({
      ...userContext,
      subAccounts: [...otherSubAccounts, {
        id: data.updateSubAccount.subAccount.id,
        name: data.updateSubAccount.subAccount.name,
        goal: { id: goalId },
        allowClientDeposits: data.updateSubAccount.subAccount.allowClientDeposits ?? true,
        state: data.updateSubAccount.subAccount.state,
        account,
      }],
      accounts: isNewAccount ? [...(userContext.accounts ?? []), account] : userContext.accounts,
    });
    if (callbackRef.current) callbackRef.current();
    setLoading(false);
  };

  const [updateSubAccountMutation] = useMutation(UPDATE_SUB_ACCOUNT, {
    onCompleted: onUpdateSubAccountCompleted,
    onError: () => setLoading(false),
  });

  const updateSubAccountTheme = (): void => {
    updateSubAccountMutation({
      variables: {
        input: {
          name: getSubAccountUniqueName(`${getAccountTypeName(subAccountRef.current.accountType as string)} ${selectedTheme?.name ?? ''}`, userContext.subAccounts ?? []),
          subAccountId: subAccountRef.current.id,
          themeId: selectedTheme?.id,
          financialProductId: subAccountRef.current.financialProductId,
        },
      },
    });
    setLoading(true);
  };
  const onCreateSubAccountCompleted = async (data: {
    createSubAccount: {
      subAccount: {
        id: string,
        name: string,
        allowClientDeposits?: boolean,
        account: { id: string, state: string },
        theme: { id: string },
        state: string,
      },
    }
  }): Promise<void> => {
    console.log({ event: 'CREATED_SUB_ACCOUNT', subAccountId: data.createSubAccount.subAccount.id });
    const account = { id: data.createSubAccount.subAccount.account.id ?? '', type: subAccountRef.current.accountType as AccountTypes };
    const otherSubAccounts = (subAccount ? userContext.subAccounts?.filter((s) => s.id !== subAccount.id) : userContext.subAccounts) ?? [];
    const isNewAccount = !userContext.accounts?.find((a) => a.id === subAccountRef.current.accountId);
    setSubAccountRef({
      id: data.createSubAccount.subAccount.id,
      accountId: data.createSubAccount.subAccount.account.id,
      themeId: data.createSubAccount.subAccount.theme.id,
    });
    setUserContext({
      ...userContext,
      subAccounts: [...otherSubAccounts, {
        id: data.createSubAccount.subAccount.id,
        name: data.createSubAccount.subAccount.name,
        goal: { id: goalId },
        allowClientDeposits: data.createSubAccount.subAccount.allowClientDeposits ?? true,
        state: data.createSubAccount.subAccount.state,
        account,
      }],
      accounts: isNewAccount ? [...(userContext.accounts ?? []), account] : userContext.accounts,
    });
    setSubAccountId(data.createSubAccount.subAccount.id);
    originalAccount.current = {
      id: data.createSubAccount.subAccount.account.id,
      themeId: subAccountRef.current.themeId,
      type: subAccountRef.current.accountType,
      state: data.createSubAccount.subAccount.account.state,
    };
    if (subAccountRef.current.accountType === AccountTypes.PERSONAL && enableTLHRef.current) {
      updateAccountMutation({
        variables: {
          input: {
            accountId: data.createSubAccount.subAccount.account.id,
            tlhEnabled: true,
          },
        },
      }).then();
    }
    if (callbackRef.current) callbackRef.current();
    setLoading(false);
  };

  const [createSubAccountMutation] = useMutation(CREATE_SUB_ACCOUNT, {
    variables: {
      input: {
        goalId,
        accountId: hasAuxStep ? '' : undefined,
        accountType: '',
        name: '',
        themeId: financialProduct?.theme?.id ? undefined : '',
        financialProductId: financialProduct?.id ? undefined : '',
        requestId: '',
      },
    },
    onCompleted: onCreateSubAccountCompleted,
    onError: () => setLoading(false),
  });
  const closeAccount = async (accountId?: string): Promise<void> => {
    await graphqlClient.mutate({
      mutation: CLOSE_ACCOUNT,
      variables: { input: { accountId: accountId ?? createdAccountRef.current.id, inactiveReason: 'OTHER' } },
    });
  };
  const createSubAccount = async (callback: () => void): Promise<void> => {
    let shouldCreateSubAccount = true;
    if (subAccountRef.current.id && originalAccount.current?.type) {
      if (originalAccount.current?.type !== subAccountRef.current.accountType) {
        setLoading(true);
        let activeSubAccounts = [];
        const fetchSubAccountResponse = await fetchSubAccount();
        // newSubAccountCreated code below makes sure that the new subAccount you created
        activeSubAccounts = fetchSubAccountResponse.data.fetchAccount.account.subAccounts.filter((item: { id: string, state: string }) => (['AWAITING_APPROVAL', 'ACTIVE'].includes(item.state) && subAccountRef.current.id !== item.id));
        // Close the account if it is not an auxiliary account and if there are no active subAccounts
        if (!hasAuxStep && originalAccount.current?.id && originalAccount.current?.state === 'INITIATED' && activeSubAccounts.length === 0) {
          await closeAccount(originalAccount.current?.id);
        } else {
          await graphqlClient.mutate({
            mutation: CLOSE_SUB_ACCOUNT,
            variables: {
              input: {
                subAccountId: subAccountRef.current.id,
                inactiveReason: 'OTHER',
              },
            },
          });
          console.log({
            event: 'CLOSED_SUB_ACCOUNT',
            subAccountId: subAccountRef.current.id,
          });
        }
        setUserContext({
          ...userContext,
          subAccounts: [...(userContext.subAccounts?.filter((s) => s.id !== subAccountRef.current.id) ?? [])],
        });
      } else {
        shouldCreateSubAccount = false;
        if (originalAccount.current?.themeId !== subAccountRef.current.themeId) {
          updateSubAccountTheme();
        }
        callback();
      }
    }
    if (shouldCreateSubAccount) {
      console.log({ event: 'CREATING_SUB_ACCOUNT' });
      callbackRef.current = callback;
      createSubAccountMutation({
        variables: {
          input: {
            goalId,
            accountId: hasAuxStep || isExistingRespAccount ? createdAccountRef.current.id : undefined,
            accountType: subAccountRef.current.accountType as AccountTypes,
            name: getSubAccountUniqueName(`${getAccountTypeName(subAccountRef.current.accountType as string)} ${financialProduct?.theme?.name ?? (selectedTheme?.name ?? '')}`, userContext.subAccounts ?? []),
            themeId: financialProduct?.theme?.id ? undefined : (selectedTheme?.id ?? ''),
            financialProductId: financialProduct?.id ? undefined : (subAccountRef.current.financialProductId ?? ''),
            requestId: generateUniqueRequestId(),
          },
        },
      }).then();
      setLoading(true);
    }
  };

  const addAffiliation = async (accountId: string, updatedAffilateUsers: AffiliateUser[]): Promise<void> => {
    const affiliateUserAffiliation: Affiliation[] = updatedAffilateUsers.map((x) => x.affiliation).filter((x) => x !== null) as Affiliation[];
    await new Promise((resolve) => setTimeout(resolve, 3000));
    updateAffiliationsMutation({
      variables: {
        input: {
          accountId,
          affiliations: [...affiliationList, ...affiliateUserAffiliation].map((affiliate) => ({
            userId: affiliate.userId,
            type: affiliate.type,
            relation: affiliate.relation,
            allocation: affiliate.allocation ?? undefined,
            signatureRequired: affiliate.signatureRequired,
          })),
        },
      },
    }).then();
    // eslint-disable-next-line
    createSubAccount(callbackRef.current!).then();
  };
  const onCreateBeneficiaryCompleted = (accountId: string, updatedAffilateUsers: AffiliateUser[]): void => {
    if (currentSubAccount?.account?.type === AccountTypes.RESP_ADULT) {
      const affiliateUser: AffiliateUser = {
        affiliation: {
          userId: userIdVar(), type: AffiliationTypes.PRIMARY_BENEFICIARY, signatureRequired: false, relation: AffiliationRelations.OTHER,
        },
      };
      setAffiliateUserList([affiliateUser]);
      addAffiliation(accountId, [affiliateUser]);
    } else {
      setAffiliateUserList(updatedAffilateUsers);
      addAffiliation(accountId, updatedAffilateUsers);
    }
  };
  const createBeneficiary = async (accountId: string): Promise<void> => {
    const updatedAffilateUsers: Array<AffiliateUser> = [];
    for (let i = 0; i < affiliateUserList.length; i += 1) {
      const affiliate = affiliateUserList[i];
      const userId: string | undefined = affiliate?.affiliation?.userId;
      const dateOfBirth: string = (affiliate.dateOfBirth && affiliate.dateOfBirth !== undefined) ? formatDate(affiliate.dateOfBirth) : '';
      if (userId) {
        updateAffiliateMutation({
          variables: {
            input: {
              ...{
                ...affiliate,
                userId,
                dateOfBirth,
                inProvinceSince: affiliate.inProvinceSince ? formatDate(affiliate.inProvinceSince) : undefined,
                affiliation: undefined,
              },
            },
          },
          onCompleted: (data) => {
            const usr: AffiliateUser = { ...affiliate, affiliation: { ...affiliate.affiliation, userId: data.updateAffiliate.user.id } };
            updatedAffilateUsers.push(usr);
            if (updatedAffilateUsers.length === affiliateUserList.length) onCreateBeneficiaryCompleted(accountId, updatedAffilateUsers);
          },
          onError: () => setLoading(false),
        }).then();
      } else {
        createAffiliateMutation({
          variables: {
            input: {
              organizationId: userContext.organization?.id,
              ...{
                ...affiliate,
                dateOfBirth,
                inProvinceSince: affiliate.inProvinceSince ? formatDate(affiliate.inProvinceSince) : undefined,
                affiliation: undefined,
              },
            },
          },
          onCompleted: (data) => {
            const usr: AffiliateUser = { ...affiliate, affiliation: { ...affiliate.affiliation, userId: data.createAffiliate.user.id } };
            updatedAffilateUsers.push(usr);
            if (updatedAffilateUsers.length === affiliateUserList.length) onCreateBeneficiaryCompleted(accountId, updatedAffilateUsers);
          },
          onError: () => setLoading(false),
        }).then();
      }
    }
    // continue the action if there is no affiliate (RRIF non-spousal use case)
    if (affiliateUserList.length === 0 && !userIsMarriedOrCommonLaw) {
      // eslint-disable-next-line
      createSubAccount(callbackRef.current!).then();
    }
  };
  const onCreateAccountActionCompleted = async (account: AccountRef): Promise<void> => {
    setCreatedAccountRef(account);
    if (account.id && currentSubAccount?.account?.type === AccountTypes.RESP_ADULT) {
      onCreateBeneficiaryCompleted(account.id, []);
    } else if (account.id) createBeneficiary(account.id);
  };

  const createAccount = async (): Promise<void> => {
    createAccountMutation({
      variables: {
        input: {
          userId: userIdVar(),
          type: currentSubAccount?.account?.type,
          applyForGovFunds,
          requestId: generateUniqueRequestId(),
        },
      },
      onCompleted: (data) => {
        onCreateAccountActionCompleted(data.createAccount.account);
      },
      onError: () => setLoading(false),
    }).then();
  };

  const updateAccount = async (): Promise<void> => {
    updateAccountMutation({
      variables: {
        input: {
          accountId: createdAccountRef.current.id,
          applyForGovFunds,
        },
      },
      onCompleted: (data) => {
        setCreatedAccountRef(data.updateAccount.account);
        // eslint-disable-next-line
        createSubAccount(callbackRef.current!);
      },
      onError: () => setLoading(false),
    });
  };

  const createAccountAction = async (): Promise<void> => {
    setLoading(true);
    let shouldCreateAccount = true;
    setCreatedAccountRef({ applyForGovFunds });
    if (createdAccountRef.current.type) {
      if (createdAccountRef.current.type !== currentSubAccount?.account?.type) {
        if (hasAuxStep) closeAccount().then();
      } else {
        shouldCreateAccount = false;
        updateAccount().then();
      }
    }
    if (shouldCreateAccount) createAccount().then();
    setLoading(false);
  };

  const createSubAccountAction = async (callback: () => void): Promise<void> => {
    if (hasAuxStep && !isExistingRespAccount) {
      callbackRef.current = callback;
      createAccountAction().then();
    } else {
      createSubAccount(callback).then();
    }
  };

  const hasRespAccount = (): boolean => {
    const foundItem = userContext.subAccounts?.find((item) => respAccounts.includes(item?.account?.type));
    return respAccounts.includes(subAccountRef.current.accountType) && !!foundItem;
  };
  const gotToNextStep = (): string => {
    if (subAccountRef.current.accountType && hasTlhEnabled(subAccountRef.current.accountType)) return 'tlhStep';
    if (hasRespAccount() && showExistingRespFlow) return 'existingRespAcc';
    if (respAccounts.includes(subAccountRef.current.accountType)) return 'respAccType';
    if ((subAccountRef.current.accountType === AccountTypes.RRIF && userIsMarriedOrCommonLaw) || subAccountRef.current.accountType === AccountTypes.RIF_SPOUSAL) return 'accountAuxiliaryStepWizard';
    if (hasGoalPortfolio) {
      if (shouldHideAgreement && onContinue) {
        onContinue();
        return '';
      }
      return 'agreements';
    }
    return 'selectTheme';
  };

  const goToPreviousStep = (): string => {
    if (isExistingRespAccount) return 'existingRespAcc';
    if (respAccounts.includes(subAccountRef.current.accountType) || (subAccountRef.current.accountType === AccountTypes.RRIF && userIsMarriedOrCommonLaw)) return 'accountAuxiliaryStepWizard';
    if (subAccountRef.current.accountType && hasTlhEnabled(subAccountRef.current.accountType)) return 'tlhStep';
    return 'type';
  };

  const handleGoBackFromAgreements = () => {
    if (hasGoalPortfolio) return goToPreviousStep();
    if (hasPortfolioDisclaimer) return 'portfolioDisclaimer';
    return 'themeDescription';
  };

  const goToPreviousStepFromThemeDescription = (): string => {
    if (hasMultipleThemes) return 'selectTheme';
    return goToPreviousStep();
  };

  const getEventForSelectTheme = (): string | undefined => {
    if (currentSubAccount
      && currentSubAccount.account
      && currentSubAccount.account.type === AccountTypes.RRIF) return ovAnalyticsEvents.rrifThemeSelect;
    return undefined;
  };

  const getEventForThemeDescription = (): string | undefined => {
    if (currentSubAccount
      && currentSubAccount.account
      && currentSubAccount.account.type === AccountTypes.RRIF) return ovAnalyticsEvents.rrifThemeHoldingsContinueButtonSelect;
    return undefined;
  };

  const getEventForAgreementsScreen = (): string | undefined => {
    if (currentSubAccount === undefined || currentSubAccount.account === undefined) return undefined;
    type typeOfAccountsToUse = 'RRSP' | 'PERSONAL' | 'TFSA' | 'RESP' | 'RRIF';
    return ovAnalyticsEvents.accountTypeAgreementsPageContinueButtonSelect[currentSubAccount.account.type as typeOfAccountsToUse];
  };

  const handleGoBackOnAuxiliaryStep = (): string => {
    if (subAccountRef.current?.accountType && [AccountTypes.RRIF, AccountTypes.RIF_SPOUSAL, AccountTypes.RRSP, AccountTypes.TFSA].includes(subAccountRef.current.accountType)) return 'type';
    return 'respAccType';
  };

  const steps: JSX.Element[] = [
    <AccountTypeStep
      accountsToExclude={accountsToExclude}
      stepProps={{ absoluteTotalSteps }}
      stepTitle={stepTitle}
      stepName="type"
      continueToNamedStep={gotToNextStep}
      currentSubAccount={currentSubAccount}
      setSubAccountRef={setSubAccountRef}
      isLoading={loading}
      goBackAction={goBack}
      key="type"
      action={(callback) => {
        if (['', 'agreements'].includes(gotToNextStep())) {
          return createSubAccountAction(callback);
        }
        return undefined;
      }}
    />,
    <EnableTLHStep
      stepProps={{ absoluteTotalSteps }}
      stepTitle={stepTitle}
      stepName="tlhStep"
      isLoading={loading}
      continueToNamedStep={() => {
        if (hasGoalPortfolio) {
          if (shouldHideAgreement && onContinue) {
            onContinue();
            return '';
          }
          return 'agreements';
        }
        return 'selectTheme';
      }}
      key="thlStep"
      setEnableTLH={setEnableTLHStep}
      action={hasGoalPortfolio ? createSubAccountAction : undefined}
    />,
    <ExistingRespAccountTypeStep
      stepProps={{ absoluteTotalSteps }}
      stepTitle={stepTitle}
      stepName="existingRespAcc"
      currentSubAccount={currentSubAccount}
      setSubAccountRef={setSubAccountRef}
      setCreatedAccountRef={setCreatedAccountRef}
      isLoading={loading}
      goBackAction={goBack}
      key="type"
      goToAnotherStep={() => {
        if (hasGoalPortfolio) {
          createSubAccount(() => console.log('callback'));
          if (shouldHideAgreement && onContinue) {
            onContinue();
            return '';
          }
          return 'agreements';
        }
        return 'selectTheme';
      }}
      setIsExistingRespAccount={setIsExistingRespAccount}
    />,
    <RespAccountTypeStep stepProps={{ absoluteTotalSteps }} stepTitle={stepTitle} stepName="respAccType" goBackToNamedStep={() => (hasRespAccount() && showExistingRespFlow ? 'existingRespAcc' : 'type')} currentSubAccount={currentSubAccount} setSubAccountRef={setSubAccountRef} key="respAccType" />,
    <AccountTypeAuxiliaryStepWizard
      stepProps={{ absoluteTotalSteps, currentStep: 10 }}
      goBackToNamedStep={handleGoBackOnAuxiliaryStep}
      affiliateUserList={affiliateUserList}
      setAffiliateUserList={setAffiliateUserList}
      stepName="accountAuxiliaryStepWizard"
      stepTitle={stepTitle}
      currentSubAccount={currentSubAccount}
      affiliationList={affiliationList}
      setApplyForGovFunds={setApplyForGovFunds}
      setAffiliationList={setAffiliationList}
      key="accountAuxiliaryStepWizard"
      disableTracker
      isLoading={loading}
      continueToNamedStep={() => {
        if (hasGoalPortfolio) {
          if (shouldHideAgreement && onContinue) {
            onContinue();
            return '';
          }
          return 'agreements';
        }
        return 'selectTheme';
      }}
      action={hasGoalPortfolio ? createSubAccountAction : undefined}
    />,
    ...(hasGoalPortfolio ? [] : [
      <SelectThemeStep stepProps={{ absoluteTotalSteps, currentStep: 22 }} analyticsEvent={getEventForSelectTheme()} setHasMultipleThemes={setHasMultipleThemes} goBackToNamedStep={goToPreviousStep} stepTitle={stepTitle} stepName="selectTheme" currentSubAccount={currentSubAccount} goalId={goalId} setSubAccountRef={setSubAccountRef} selectedTheme={selectedTheme} setSelectedTheme={setSelectedTheme} key="selectTheme" />,
      <ThemeDescriptionStep
        stepProps={{ absoluteTotalSteps, currentStep: 23 }}
        analyticsEvent={getEventForThemeDescription()}
        setHasPortfolioDisclaimer={setHasPortfolioDisclaimer}
        continueToNamedStep={() => {
          if (shouldHideAgreement && onContinue) {
            onContinue();
            return '';
          }
          if (hasPortfolioDisclaimer) return 'portfolioDisclaimer';
          return 'agreements';
        }}
        goBackToNamedStep={goToPreviousStepFromThemeDescription}
        stepTitle={stepTitle}
        stepName="themeDescription"
        goalId={goalId}
        setSubAccountRef={setSubAccountRef}
        selectedTheme={selectedTheme}
        action={!hasPortfolioDisclaimer ? createSubAccountAction : undefined}
        showLoading={loading}
        key="themeDescription"
      />,
      <PortfolioDisclaimerStep stepProps={{ absoluteTotalSteps, currentStep: 24 }} stepTitle={stepTitle} stepName="portfolioDisclaimer" financialProductDisclaimer={subAccountRef.current.financialProductDisclaimer ?? ''} action={createSubAccountAction} key="portfolioDisclaimer" />,
    ]),
    ...(shouldHideAgreement ? [] : [
      <IncompleteAgreementsStep
        stepProps={{ absoluteTotalSteps, currentStep: 25 }}
        analyticsEvent={getEventForAgreementsScreen()}
        goBackToNamedStep={handleGoBackFromAgreements}
        stepTitle={t('user:agreements.stepWizardTitle')}
        stepName="agreements"
        subAccountId={subAccountId}
        action={() => {
          if (onContinue) { onContinue(); }
          if (onSubAccountCreated) { onSubAccountCreated(subAccountRef.current); }
        }}
        key="agreements"
      />]),
  ];

  return (
    <StepWizard
      instance={setInstance}
      isLazyMount
      transitions={{}}
      onStepChange={(stepChange: {
        previousStep: number
        activeStep: number
      }) => {
        if (onStepChanged) {
          onStepChanged(stepChange.activeStep);
        }
      }}
      className="ov-step-wizard"
      nav={(
        <TrackingBar
          steps={steps}
          baselineStepProps={stepProps}
        />
      )}
    >
      {steps}
    </StepWizard>
  );
};

CreateSubAccountWizard.defaultProps = {
  subAccount: undefined,
  onContinue: undefined,
  goBack: undefined,
  financialProduct: undefined,
  stepTitle: undefined,
  stepProps: undefined,
  onStepChanged: undefined,
  showExistingRespFlow: false,
  onSubAccountCreated: undefined,
  shouldHideAgreement: false,
  accountsToExclude: [],
};

export default CreateSubAccountWizard;
